import { base } from '../../store/api';

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function SliderImage(props) {
  console.log(props?.images?.length > 1, props?.images?.length, props?.images)

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: true,
  };
  return (

    <Slider {...settings} className='detail-slider'
    >
      {
        props.images.length ? props.images.map((item) => {
          return (
            <div
              key={item.id}
              className="main-slider  d-flex justify-content-center align-items-center bg-overlay-secondary overlay-30"
            >
              <img src={`${base}${item.thumbnail}`} alt='' />
            </div>
          )
        }) : ''}
    </Slider>


  );
}

export default SliderImage;
