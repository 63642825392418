/* eslint-disable no-unused-vars */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getVideo } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import VideoPopup from '../../component/video';
import { base } from '../../store/api';
import Loading from '../../component/Loading';

function Video() {
  const dispatch = useDispatch()
  const [news, setNews] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    setLoading(true)
    getVideo(dispatch, {}, lang, (err, res) => {
      if (err) console.log(err);
      setNews(res?.results)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentPage])



  return (
    <>
      <div className="site-content">
        <PageHeader
          title={t("video")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <Col sm={12}>
                  <div className="blog-wrapper grid-wrapper grid-lg-3 grid-md-2 grid-sm-1">
                    {news.length ? news.map((img, index) => {
                      return (
                        <VideoPopup
                          key={index}
                          videoURL={`${base}${img.video}`}
                          videoImage={`${base}${img.cover_photo}`} />
                      )
                    }) : ''}
                  </div>

                </Col>
              }
            </Row>

          </Container>
        </div>
      </div>
    </>
  );
}

export default Video;
