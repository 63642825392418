/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { getInstitutionCategories } from '../../store/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../component/Loading';

const BlogSidebar = (props) => {
  const { t } = useTranslation()
  const [news, setNews] = useState([])
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = React.useState(true)


  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    setLoading(true)
    getInstitutionCategories(dispatch, {}, lang, (err, res) => {
      if (err) console.log(err);
      setNews(res?.results)
      setLoading(false)
    })
  }, [language])

  return (
    <div className="sidebar-widget-wrapper">
      <div className="sidebar__widget mb-30">
        <div className="sidebar__widget-head mb-35">
          <h4 className="sidebar__widget-title">{t("other_educations")}</h4>
        </div>
        <div className="sidebar__widget-content">
          {loading ?
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
              <Loading style={{ width: "30%" }} />
            </div>
            :
            <div className="rc__post-wrapper">
              {
                news.length ? news.map((item) => (

                  <Link to={`/education/${item.id}`} key={item.id} className={`rc__post d-flex align-items-center ${props.id === item.id ? 'active_post' : ''}`}>

                    <div className="rc__content">
                      <div className="rc__title ">
                        <div className='first-letter-capitalize'>
                          {item.title}
                        </div>
                      </div>
                    </div>
                  </Link>
                )) : ''
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;