export default function replaceWithSpace(html) {
  let content = html.replaceAll(/\r\n/g, '')
  content = content.replaceAll(/<p>/g, '<p>&Tab;')
  content = content.replaceAll(/ <p/g, '<p')
  content = content.replaceAll(/ <p/g, '<p')
  content = content.replaceAll(/ <p/g, '<p')
  content = content.replaceAll(/ <p/g, '<p')
  content = content.replaceAll(/ <p/g, '<p')
  content = content.replaceAll(/ <p/g, '<p')
  content = content.replaceAll(/<br \/>/g, '')
  content = content.replaceAll(/"\/media\/ckeditor/g, '"https://education.gov.tm/media/ckeditor')
  return content
}