import React, { useState } from 'react';
import Lightbox from 'react-18-image-lightbox';
import '../../component/image-gallery/style.scss';
import { base } from '../../store/api';

function ImageGallery(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    console.log(props)
  }, [props])

  return (
    <div className='image-gallery'>
      <div className='grid-wrapper grid-lg-3 grid-md-2 grid-sm-1 grid-xs-1 align-items-center'>
        {props.data.map((img, index) => {
          return (
            <div key={index} className={`gallery-item ${props.theme || ''}`}>
              <div className="gallery-image relative">
                <img
                  className="img-fluid"
                  key={index}
                  src={`${base}${img.thumbnail}`}
                  alt={img.title}
                  onClick={() => openLightbox(index)}
                />
              </div>
            </div>

          )
        })}
      </div>

      {isOpen && (
        <div style={{ position: "relative" }}>
          <Lightbox
            imagePadding={50}
            mainSrc={`${base}${props.data[photoIndex].thumbnail}`}
            imageTitle={props.data[photoIndex].title}
            nextSrc={props.data[(photoIndex + 1) % props.data.length]}
            prevSrc={
              props.data[(photoIndex + props.data.length - 1) % props.data.length]
            }
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + props.data.length - 1) % props.data.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % props.data.length)
            }
          />
          <div style={{ position: "fixed", left: 0, right: 0, bottom: "15%", zIndex: 10001 }}>

            <p style={{textAlign: "center"}}>{props.data[photoIndex].source_link !== null && props.data[photoIndex].source_link !== undefined ?
              `Source: ${props.data[photoIndex].source_link} ` : null}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGallery;
