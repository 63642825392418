import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getSingleGallery } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/dist/umd/react-router-dom.development';
import ImageGallery from './imageGallery';
import Loading from '../../component/Loading';

function GalleryDetails() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [gallery, setGallery] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    const lang = language === 'tm' ? 'tk' : language
    setLoading(true)
    getSingleGallery(dispatch, id, lang, (err, res) => {
      if (err) console.log(err);
      let entries = Object.entries(res)
      let arr = []
      arr.push(entries[0][1])
      entries[1][1].forEach(e => arr.push(e))
      setGallery(arr)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])



  return (
    <>
      <div className="site-content">
        <PageHeader
          title={t("photo")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <Col sm={12}>
                  <ImageGallery
                    theme="gallery-style-2"
                    data={gallery}
                    video_index={gallery.length}
                  />
                </Col>
              }
            </Row>

          </Container>
        </div>
      </div>
    </>
  );
}

export default GalleryDetails;
