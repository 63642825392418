/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { getSingleArticle } from '../../store/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import BlogSidebar from './sidebar_article';
import moment from 'moment/moment';
import './blog.scss'
import { formatMonth } from '../../data/format-month';
import Slider from './slider';
import replaceWithSpace from '../../data/replace';
import { HiOutlineEye } from 'react-icons/hi2';

function ArticleSingle() {
  let { id } = useParams();
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')

  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    const lang = language === 'tm' ? 'tk' : language
    getSingleArticle(dispatch, id, lang, (err, res) => {
      if (err) console.log(err);
      setData(res?.data)
    })
  }, [language, id])

  return (
    <>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("articles")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              <Col xl={8} lg={12}>
                <article className="about-post blog-post">
                  <div className='entry-content'>
                    <div className="entry-meta">
                      <ul>
                        <li key="meta-category">
                          <img src='/images/calendar.svg' className='' alt='calendar' />
                        </li>
                        <li key="meta-date" className="entry-meta-date">
                          <div>{Object.keys(data).length ? formatDate(data.timestamp) : ''}</div>
                        </li>
                        <li>
                          <div><HiOutlineEye size={22} /> {data.view_count}</div>
                        </li>
                      </ul>
                    </div>
                    <h4 className="entry-title pt-2">{Object.keys(data).length ? data.title : ''}</h4>
                  </div>
                  <div className="post-image">
                    <Slider images={Object.keys(data).length ? data.images : ''} />
                  </div>
                  <div className="entry-content">
                    <div className="entry-description">
                      <div dangerouslySetInnerHTML={{ __html: Object.keys(data).length ? replaceWithSpace(data.content) : '' }}></div>
                    </div>
                  </div>

                  {
                    data.source_link
                      ?
                      <div className='entry-button' style={{ width: 250, marginTop: 40, float: 'right' }}>
                        <Link target='_blank' to={data.source_link} className='entry-button-link font-rubik'>
                          <span>{t('source')}</span>
                        </Link>
                      </div>
                      :
                      null
                  }
                </article>
              </Col>
              <Col xl={4} lg={8} md={8}>
                <BlogSidebar news_id={id} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ArticleSingle;
