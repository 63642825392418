/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getInstitutionCategories, getInstitutions } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import Pagination from '../../component/pagination';
import { useParams } from 'react-router-dom/dist/umd/react-router-dom.development';
import BlogSidebar from './sidebar_article';
// 
import './style.scss'
import { base } from '../../store/api';
import Loading from '../../component/Loading';

function BlogClassic(props) {
  const { t } = useTranslation()


  return (
    <div>
      <article className={`blog-post relative article-post blog-classic d-flex align-items-center gap-4 ${props.postType || ''}`}>
        <div className="w-50 education-image">
          <img
            className="w-100 img-fluid"
            src={props.imgSrc}
            alt={props.title}
          />
          <svg viewBox="0 0 20 104" fill="currentColor">
            <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104"></polygon>
          </svg>
        </div>
        <div className="w-50 entry-content d-flex flex-column gap-4">

          <h4 className="entry-title education-title">
            <Link to={{ pathname: `/education-details/${props.id}` }}>
              {props.title}
            </Link>
          </h4>
          {props.official_site !== null ?
            <a href={props.official_site} target='_blank' style={{ color: '#00843d' }}>{t("officialSite")} <i className='fas fa-chevron-right' style={{ fontSize: "11px", marginLeft: '10px' }}></i></a>
          : <></> }
        </div>
      </article>
    </div>
  );
}

function Education() {
  const dispatch = useDispatch()
  const [news, setNews] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { categoryId } = useParams()
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(true)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [language, categoryId, currentPage])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    getInstitutionCategories(dispatch, {}, lang, (err, res) => {
      if (err) console.log(err);
      res.results.length && res.results.forEach(e => e.id === Number(categoryId) ? setTitle(e.title) : '')
    })


  }, [language, categoryId])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    let params = {
      limit: 20,
      offset: (currentPage - 1) * 20,
      category_id: categoryId
    }
    setLoading(true)
    getInstitutions(dispatch, params, lang, (err, res) => {
      if (err) console.log(err);
      setNews(res?.results)
      setCount(res?.count)
      setLoading(false)

    })

  }, [language, categoryId, currentPage])





  return (
    <>
      <div className="site-content">
        <PageHeader
          title={title ? title : t("vocational_education")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center row-reverse">
              {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :

                <Col xl={8} lg={12} md={12}>
                  <div className="blog-wrapper grid-wrapper grid-lg-1 grid-md-1 grid-sm-1 grid-xs-1">
                    {news.length ? news.map((val, ind) => {
                      return (
                        <BlogClassic
                          key={ind}
                          id={val.id}
                          imgSrc={`${base}${val.thumbnail}`}
                          date={val.date_added}
                          title={val.title}
                          official_site={val.official_site}
                          description={val.description}
                        />
                      );
                    }) : ''}
                  </div>
                </Col>
              }
              {loading ? null :
                <Col xl={4} lg={12} md={12}>
                  <BlogSidebar id={categoryId} />
                </Col>
              }
            </Row>

            <Pagination currentPage={currentPage} count={count} handlePageChange={handlePageChange} />
          </Container>
        </div>
      </div>
    </>
  );
}

export default Education;
