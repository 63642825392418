/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { getSingleCompetition } from '../../store/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import BlogSidebar from './sidebar';
import moment from 'moment/moment';
import '../blog/blog.scss'
import { formatMonth } from '../../data/format-month';
import { base } from '../../store/api';
import replaceWithSpace from '../../data/replace';
import Loading from '../../component/Loading';

function BlogSingle() {
  let { id } = useParams();
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = useState(true)

  const formatDate = (date) => {
    console.log(date)
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const lang = language === 'tm' ? 'tk' : language
    setLoading(true)
    getSingleCompetition(dispatch, id, lang, (err, res) => {
      if (err) console.log(err);
      setData(res?.data)
      setLoading(false)
    })


  }, [language, id])


  return (
    <>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("competitions")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <Col xl={8} lg={12}>
                  <article className="about-post blog-post">
                    <div className='entry-content'>
                      <div className="entry-meta">
                        <ul>
                          <li key="meta-category">
                            <img src='/images/calendar.svg' className='' alt='calendar' />
                          </li>
                          <li key="meta-date" className="entry-meta-date">
                            <div>{Object.keys(data).length ? formatDate(data.start_date) : ''}</div>
                          </li>
                        </ul>
                      </div>
                      <h4 className="entry-title pt-2">{Object.keys(data).length ? data.title : ''}</h4>

                    </div>
                    <div className="post-image">
                      {Object.keys(data).length && data.image ? <img className="w-100" src={Object.keys(data).length ? `${base}${data.image}` : ''} alt="" /> : ''}
                    </div>
                    <div className="entry-content ">
                      <div className="entry-description">
                        <div dangerouslySetInnerHTML={{ __html: Object.keys(data).length ? replaceWithSpace(data.content) : '' }}></div>
                      </div>
                    </div>
                  </article>
                </Col>
              }
              {loading ? null :
                <Col xl={4} lg={8} md={8}>
                  <BlogSidebar news_id={id} />
                </Col>
              }
            </Row>
          </Container>
        </div>

        {/* <div className="competition event-ara page-template content-wrapper blog-post about-post">
        <div className="container">
          <div className='entry-content'>
            <h4 className="entry-title mb-4">{t("rules")}</h4>
               
          </div>
          <div className="row">
            <div className="col-sm-12">
            <div className='grid-wrapper grid-lg-2 grid-md-1'>
            {Object.keys(data).length && data?.rules.length ? data?.rules.map((item) => (
                <div key={item.id} className="single-item">
                  <div className='single-item-content rule-content relative'>
                    <div className="event_info">
                    <p dangerouslySetInnerHTML={{ __html: item.description}}></p>
                    </div>
                  </div>
                  
                </div>
              )) : ''}
              </div>
            </div>
          </div>
          <div className='compete-button'>
          <Link to={Object.keys(data).length ? data.form_url : ''} className='get-btn d-flex align-items-center justify-content-between'>
            <div>{t("compete")}</div>
          </Link>
          </div>
        </div>
        </div> */}
      </div>
    </>
  );
}

export default BlogSingle;
