/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getGallery } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-18-image-lightbox';
import { base } from '../../store/api';
import Loading from '../../component/Loading';


function Photo() {
  const dispatch = useDispatch()
  const [news, setNews] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(true)

  const closeLightbox = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    setLoading(true)
    getGallery(dispatch, {}, lang, (err, res) => {
      if (err) console.log(err);
      setLoading(false)
      setNews(res?.results)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentPage])



  return (
    <>
      <div className="site-content">
        <PageHeader
          title={t("photo")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <Col sm={12}>
                  <div className="blog-wrapper grid-wrapper grid-lg-3 grid-md-2 grid-sm-1">
                    {news.length ? news.map((img, index) => {
                      return (
                        <div key={index} className={`gallery-item gallery-style-2`}>
                          <div className="gallery-image relative">
                            <img
                              className="img-fluid"
                              key={index}
                              src={`${base}${img.thumbnail}`}
                              alt={img.title}
                            />
                          </div>
                          <Link to={`/gallery-details/${img.id}`} className="gallery-overlay">
                            <div className='overlay-text'>{img.title}</div>
                          </Link>
                        </div>
                      )
                    }) : ''}
                  </div>

                  {isOpen && (
                    <Lightbox
                      imagePadding={50}
                      mainSrc={`${base}${news[photoIndex].thumbnail}`}
                      imageTitle={news[photoIndex].title}
                      nextSrc={news[(photoIndex + 1) % news.length]}
                      prevSrc={
                        news[(photoIndex + news.length - 1) % news.length]
                      }
                      onCloseRequest={closeLightbox}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + news.length - 1) % news.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % news.length)
                      }
                    />
                  )}
                </Col>
              }
            </Row>

          </Container>
        </div>
      </div>
    </>
  );
}

export default Photo;
