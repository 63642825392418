/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import moment from 'moment/moment';
import { getArticles } from '../../store/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMonth } from '../../data/format-month';
import { base } from '../../store/api';

const BlogSidebar = (props) => {

  const {t} = useTranslation()
  const [news, setNews] = useState([])
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')

  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    let params = {
        limit : 7,
        offset : 0
      }
    getArticles(dispatch, params, lang, (err, res) => {
      if(err) console.log(err);
      setNews(res?.results)
      console.log(news)
    })

    
  },[language])

    return (
        <div className="sidebar-widget-wrapper">
            <div className="sidebar__widget mb-30">
                <div className="sidebar__widget-head mb-35">
                    <h4 className="sidebar__widget-title">{t("other_articles")}</h4>
                </div>
                <div className="sidebar__widget-content">
                    <div className="rc__post-wrapper">
                        {
                            news.length ? news.map((item)=>(
                                item.id === Number(props.news_id) ? '' :
                                <div key={item.id} className="rc__post d-flex align-items-center">
                                <div className="rc__thumb mr-20">
                                   <Link to={`/article-details/${item.id}`}>
                                   {<img src={item.images.length ? `${base}${item.images[0].thumbnail}`: ''} style={{width:'auto',aspectRatio:'1:1'}} alt="img not found" />}
                                   </Link>
                                </div>
                                <div className="rc__content">
                                   <div className="rc__meta">
                                      <span className='capitalize'>{formatDate(item.timestamp)}</span>
                                   </div>
                                   <h6 className="rc__title">
                                      <Link to={`/article-details/${item.id}`}>
                                         {item.title}
                                      </Link>
                                   </h6>
                                </div>
                             </div>
                            )) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogSidebar;