import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Article from '../pages/blog/Article';
import ArticleSingle from '../pages/blog/article-details';
import Education from '../pages/education';
import EducationSingle from '../pages/education/details';
import Photo from '../pages/gallery/photo';
import Video from '../pages/gallery/video';
import PDFViewer from '../pages/home/rating-details';
import Document from '../pages/home/document-details';
import DigitalSingle from '../pages/blog/digital-details';
import GalleryDetails from '../pages/gallery/gallery-details';
import Indicators from '../pages/education/indicators';
import Competitions from '../pages/competitions';
import CompetitionSingle from '../pages/competitions/blog-details';
const HomeIndex = React.lazy(() => import("../pages/home/index"));
const Blog = React.lazy(() => import('../pages/blog/Blog'));
const BlogSingle = React.lazy(() => import('../pages/blog/blog-details'));
const ContactUs = React.lazy(() => import('../pages/contact/contact'));
const Error404 = React.lazy(() => import('../pages/error404/error404'));
const AboutUs = React.lazy(() => import('../pages/about-us'));
const HightSchoolRankings = React.lazy(() =>
  import('../pages/high-school-rankings')
);


const RootRouter = () => {
  return (
    <Routes>
      <Route index path="/" element={<HomeIndex />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/news" element={<Blog />} />
      <Route path="/article" element={<Article />} />
      <Route path="/photos" element={<Photo />} />
      <Route path="/gallery-details/:id" element={<GalleryDetails />} />
      <Route path="/videos" element={<Video />} />
      <Route path="/education/:categoryId" element={<Education />} />
      <Route path="/indicators" element={<Indicators />} />
      <Route path="/document" element={<Document />} />
      <Route path="/competitions" element={<Competitions />} />
      <Route path="/news-details/:id" element={<BlogSingle />} />
      <Route path="/competition-details/:id" element={<CompetitionSingle />} />
      <Route path="/digital/:name" element={<DigitalSingle />} />
      <Route path="/article-details/:id" element={<ArticleSingle />} />
      <Route path="/rating-details" element={<PDFViewer />} />
      <Route path="/education-details/:id" element={<EducationSingle />} />
      <Route path="/*" element={<Error404 />} />
      <Route path="/about/:id" element={<AboutUs />} />
      <Route path="/high-school-rankings" element={<HightSchoolRankings />} />
    </Routes>
  )
}

export default RootRouter